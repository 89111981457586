export const SHOW_SCANNER = 'SHOW_SCANNER';
export const HIDE_SCANNER = 'HIDE_SCANNER';

export const DISABLE_MANAGE_PARCEL = 'DISABLE_MANAGE_PARCEL';
export const ENABLE_MANAGE_PARCEL = 'ENABLE_MANAGE_PARCEL';
export const ADD_PARCEL = 'ADD_PARCEL';
export const SET_CURRENT_PARCEL = 'SET_CURRENT_PARCEL';
export const RESET_CURRENT_PARCEL = 'RESET_CURRENT_PARCEL';
export const RESET_PARCELS = 'RESET_PARCELS';
export const RESET_STATE = 'RESET_STATE';
