import { createContext, useContext } from 'react';
import { InitialScannerState } from './scanner-reducer';
import { CurrentParcelType } from './scanner-state';

export type InitialScannerContext =
  | (InitialScannerState & {
      showScanner: () => void;
      hideScanner: () => void;
      disableManageParcel: () => void;
      enableManageParcel: () => void;
      setCurrentParcel: (
        id: string,
        parcelId: string,
        contragent: { id: string; name: string }
      ) => void;
      resetCurrentParcel: () => void;
      resetParcels: () => void;
      resetState: () => void;
      addParcel: (parcel: CurrentParcelType) => void;
    })
  | null;

export const useGetScanner = () => {
  const context = useContext(ScannerContext);
  if (!context) {
    throw new Error('useGetScanner must be used within a ScannerState');
  }
  return context;
};

export const ScannerContext = createContext<InitialScannerContext>(null);
