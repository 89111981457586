export type AuthResponse = { status: 'ok' } | { status: 'error'; message: string };

export const hasMessage = (object: unknown): object is { message: string } => {
  return !!object && typeof (object as { message: unknown })?.message === 'string';
};

export const getErrorMessage = (object: unknown): string => {
  if (hasMessage(object)) {
    return object.message;
  }
  return '';
};

export const statusOk = (): AuthResponse => ({ status: 'ok' });

export const statusError = (object?: unknown): AuthResponse => ({
  status: 'error',
  message: getErrorMessage(object)
});

export const login = async (): Promise<AuthResponse> => {
  try {
    const url = `/api/auth/login?redirect=${window.location.origin}`;
    const response = await fetch(url);
    const data: { redirectUrl: string } = await response.json();

    if (response.status !== 200) {
      console.error('[login error]', data);
      return statusError(data);
    }

    if ('redirectUrl' in data) {
      window.location.href = data.redirectUrl;
      return statusOk();
    }

    return statusError();
  } catch (error) {
    console.error('[login error]', error);
    return statusError(error);
  }
};

export const callback = async (code: string): Promise<AuthResponse> => {
  try {
    const url = `/api/auth/callback?code=${code}&redirect=${window.location.origin}`;
    const response = await fetch(url);
    const data: AuthResponse = await response.json();

    if (response.status !== 200) {
      console.error('[callback error]', response);
      return statusError(data);
    }

    return data;
  } catch (error) {
    console.error('[callback error]', error);
    return statusError(error);
  }
};

export const refresh = async (): Promise<AuthResponse> => {
  try {
    const url = `/api/auth/refresh?redirect=${window.location.origin}`;
    const response = await fetch(url);
    const data: AuthResponse = await response.json();

    if (response.status !== 200) {
      console.error('[refresh error]', data);
      return statusError(data);
    }

    return data;
  } catch (error) {
    console.error('[refresh error]', error);
    return statusError(error);
  }
};

export const logout = async (): Promise<AuthResponse> => {
  try {
    const url = `/api/auth/logout?redirect=${window.location.origin}`;
    const response = await fetch(url);
    const data: { redirectUrl: string } = await response.json();

    if (response.status !== 200) {
      console.error('[logout error]', data);
      return statusError(data);
    }

    if ('redirectUrl' in data) {
      window.location.href = data.redirectUrl;
      return statusOk();
    }

    return statusError();
  } catch (error) {
    console.error('[logout error]', error);
    return statusError(error);
  }
};
