import React, { useCallback, useMemo, useReducer } from 'react';
import {
  ADD_PARCEL,
  DISABLE_MANAGE_PARCEL,
  ENABLE_MANAGE_PARCEL,
  HIDE_SCANNER,
  RESET_CURRENT_PARCEL,
  RESET_PARCELS,
  RESET_STATE,
  SET_CURRENT_PARCEL,
  SHOW_SCANNER
} from '../types';
import { ScannerContext } from './scanner-context';
import { scannerReducer } from './scanner-reducer';

type ScannerStateProps = {
  children: React.ReactNode;
};

export type ContrAgentType = {
  id: string;
  name: string;
};

export enum Role {
  operator = 'operator',
  courier = 'courier',
  guest = 'guest'
}

export type CurrentParcelType = {
  id: string;
  parcelId: string;
  contragent: ContrAgentType;
  status: {
    name: string;
    comment?: string;
    personaId?: string | null;
    role: Role;
  };
};

const storageParcels = JSON.parse(sessionStorage.getItem('parcels') ?? '[]');

export const initialState = {
  isDisabledManageParcel: true,
  isShowScanner: true,
  currentParcel: {
    id: null,
    parcelId: null,
    contragent: { id: '', name: '' }
  },
  parcels: storageParcels
};

export function updateParcelsState(storedParcels: CurrentParcelType[], parcel: CurrentParcelType) {
  return storedParcels.map((p: CurrentParcelType) => {
    if (p.parcelId === parcel.parcelId) {
      return parcel;
    }
    return p;
  });
}

export const ScannerState = ({ children }: ScannerStateProps) => {
  const [state, dispatch] = useReducer(scannerReducer, initialState);

  const showScanner = useCallback(() => {
    dispatch({ type: SHOW_SCANNER });
  }, [dispatch]);

  const hideScanner = useCallback(() => {
    dispatch({ type: HIDE_SCANNER });
  }, [dispatch]);

  const enableManageParcel = useCallback(() => {
    dispatch({ type: ENABLE_MANAGE_PARCEL });
  }, [dispatch]);

  const disableManageParcel = useCallback(() => {
    dispatch({ type: DISABLE_MANAGE_PARCEL });
  }, [dispatch]);

  const setCurrentParcel = useCallback(
    (id: string, parcelId: string, contragent: ContrAgentType) => {
      dispatch({ type: SET_CURRENT_PARCEL, payload: { id, parcelId, contragent } });
    },
    [dispatch]
  );

  const resetCurrentParcel = useCallback(() => {
    dispatch({ type: RESET_CURRENT_PARCEL });
  }, [dispatch]);

  const addParcelInLocalStorage = (parcel: CurrentParcelType) => {
    const storedParcels = JSON.parse(sessionStorage.getItem('parcels') ?? '[]');

    if (storedParcels.some((p: CurrentParcelType) => p.parcelId === parcel.parcelId)) {
      const updatedParcels = updateParcelsState(storedParcels, parcel);
      sessionStorage.setItem('parcels', JSON.stringify(updatedParcels));
      return;
    }

    sessionStorage.setItem('parcels', JSON.stringify([...storedParcels, parcel]));
  };

  const addParcel = useCallback(
    (parcel: CurrentParcelType) => {
      dispatch({ type: ADD_PARCEL, payload: parcel });

      addParcelInLocalStorage(parcel);
    },
    [dispatch]
  );

  const resetParcels = useCallback(() => {
    dispatch({ type: RESET_PARCELS });
    sessionStorage.setItem('parcels', JSON.stringify([]));
  }, [dispatch]);

  const resetState = useCallback(() => {
    dispatch({ type: RESET_STATE });
    sessionStorage.setItem('parcels', JSON.stringify([]));
  }, [dispatch]);

  const contextValue = useMemo(
    () => ({
      ...state,
      showScanner,
      hideScanner,
      enableManageParcel,
      disableManageParcel,
      setCurrentParcel,
      resetCurrentParcel,
      resetParcels,
      resetState,
      addParcel
    }),
    [
      state,
      showScanner,
      hideScanner,
      enableManageParcel,
      disableManageParcel,
      setCurrentParcel,
      resetCurrentParcel,
      resetParcels,
      resetState,
      addParcel
    ]
  );

  return <ScannerContext.Provider value={contextValue}>{children}</ScannerContext.Provider>;
};
