import { FC } from 'react';
import { useRouteError } from 'react-router-dom';
import { errorMessages, ErrorMessagesTypes } from '../constatns';

export const ErrorDetails: FC<{ error?: Error | null }> = ({ error }) => {
  if (!error) {
    return null;
  }

  return (
    <div className="mt-4">
      <div className="mb-2 flex items-center justify-between">
        <p>Деталі помилки:</p>
        <button
          className="btn btn-xs btn-outline"
          onClick={() => navigator.clipboard.writeText(error.toString())}>
          Копіювати
        </button>
      </div>
      <div className="mb-4">
        <pre className="bg-base-300 overflow-x-auto rounded-md p-2">{error.toString()}</pre>
      </div>

      <div className="mb-2 flex items-center justify-between">
        <p>Стек викликів:</p>
        <button
          className="btn btn-xs btn-outline"
          onClick={() => navigator.clipboard.writeText(error?.stack ?? '')}>
          Копіювати
        </button>
      </div>
      <div className="">
        <pre className="bg-base-300 overflow-x-auto rounded-md p-2">{error?.stack ?? ''}</pre>
      </div>
    </div>
  );
};

export const ErrorMessage: FC<{ error?: Error | null }> = ({ error }) => {
  const routeError = useRouteError() as Error;
  return (
    <div className="container flex h-screen flex-col flex-wrap items-center justify-center px-5">
      <div role="alert" className="alert alert-error w-72">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>{errorMessages[ErrorMessagesTypes.default]}</span>
      </div>
      <div className="w-72">
        {error && <ErrorDetails error={error} />}
        {routeError && <ErrorDetails error={routeError} />}
      </div>
    </div>
  );
};
