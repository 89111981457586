import { getCookie, isCookieExpired, login, refresh } from '../utils';

export const checkAuthCookies = async (): Promise<string | null> => {
  if (import.meta.env.VITE_ENABLE_AUTH === 'false') {
    return null;
  }

  const expiresInDate = getCookie('expires_in');

  if (!expiresInDate) {
    const result = await login();
    return result.status === 'ok' ? null : result.message;
  }

  if (expiresInDate && isCookieExpired(expiresInDate)) {
    const refreshResult = await refresh();

    if (refreshResult.status === 'error') {
      const result = await login();
      return result.status === 'ok' ? null : result.message;
    }
  }

  return null;
};

export const isAuthenticated = async (): Promise<boolean> => {
  if (import.meta.env.VITE_ENABLE_AUTH === 'false') {
    return true;
  }

  const expiresInDate = getCookie('expires_in');

  if (!expiresInDate) {
    return false;
  }

  if (expiresInDate && isCookieExpired(expiresInDate)) {
    const refreshResult = await refresh();

    if (refreshResult.status === 'error') {
      return false;
    }
  }

  return true;
};
